/* Chat container */
.chat-container {
    max-width: 700px;
    margin: 40px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    font-family: Arial, sans-serif;
    background-color: white;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

/* Title Styling */
.title {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
}

/* Message container (scrollable) */
.message-container {
    height: 500px;
    overflow-y: auto;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
}

/* Input area */
.input-container {
    display: flex;
    margin-top: 10px;
    gap: 5px; /* Adds space between input and button */
}

.input {
    flex-grow: 1;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.button {
    padding: 10px;
    cursor: pointer;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    transition: background-color 0.2s ease-in-out;
}

.button:hover {
    background-color: #0056b3;
}

/* Loading text when bot is thinking */
.loading-text {
    text-align: center;
    font-style: italic;
    color: gray;
}

/* Message alignment */
.user-message {
    text-align: right;
    margin: 5px 0;
}

.bot-message {
    text-align: left;
    margin: 5px 0;
}

/* Message bubble design */
.message-bubble {
    display: inline-block;
    padding: 8px 12px;
    border-radius: 10px;
    max-width: 80%;
    word-wrap: break-word;
    animation: fadeIn 0.3s ease-out; /* ✅ Smooth fade-in effect */
    overflow-wrap: break-word;
}

/* User messages */
.user-message .message-bubble {
    background: #007bff;
    color: white;
    text-align: left;
}

/* Bot messages */
.bot-message .message-bubble {
    background: #e5e5e5;
    color: black;
    /* Add styles for markdown content */
}

/* Smooth fade-in animation */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Ensures smooth scrolling when new messages are added */
.message-container::-webkit-scrollbar {
    width: 6px;
}

.message-container::-webkit-scrollbar-thumb {
    background: #bbb;
    border-radius: 3px;
}

.message-container::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Markdown styles */
.message-bubble pre {
    background-color: #f6f8fa;
    padding: 16px;
    border-radius: 6px;
    overflow-x: auto;
}

.message-bubble code {
    background-color: rgba(175, 184, 193, 0.2);
    padding: 0.2em 0.4em;
    border-radius: 6px;
    font-family: monospace;
}

.message-bubble p {
    margin: 0.5em 0;
}

.message-bubble ul,
.message-bubble ol {
    margin: 0.5em 0;
    padding-left: 20px;
}
