/* Navbar Styles */
.navbar {
  background-color: var(--dark-default);
  height: auto;
  min-height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 999;
  padding: 0.5rem;
  flex-wrap: wrap;
}

/* Dynamic background color classes */
.navbar--blue {
  background-color: blue;
}

.navbar--white {
  background-color: white;
}

/* Navbar container */
.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1500px;
  padding: 0 1rem;
  overflow-x: auto; /* Enables horizontal scrolling if needed */
  white-space: nowrap;
}

/* Logo Styles */
.navbar-logo {
  color: white;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  flex-shrink: 0; /* Prevents logo from shrinking */
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

/* Navigation Menu */
.nav-menu {
  display: flex;
  flex-wrap: wrap; /* Allows items to wrap */
  justify-content: flex-end;
  list-style: none;
  text-align: center;
  gap: 10px;
  padding: 0;
  margin-right: 2rem;
  width: auto;
  overflow-x: auto; /* Enables scrolling for large menus */
}

/* Navigation Items */
.nav-item {
  height: auto;
}

/* Navigation Links */
.nav-links {
  color: white;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  font-size: clamp(0.8rem, 2vw, 1.2rem); /* Dynamic font size */
  transition: all 0.2s ease-out;
  flex-shrink: 0;
}

.nav-links:hover {
  border-bottom: 4px solid white;
}

/* Mobile Menu Button */
.menu-icon {
  display: none;
  cursor: pointer;
}

/* Responsive Design */
@media screen and (max-width: 960px) {
  .navbar {
    padding: 0.5rem 1rem;
  }

  .navbar-container {
    flex-direction: column;
    align-items: center;
    overflow-x: visible;
  }

  .navbar-logo {
    font-size: 1.8rem;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 15px;
    right: 20px;
    font-size: 1.8rem;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 0;
    transition: all 0.5s ease;
    background: rgba(198, 236, 255, 0.9);
    padding: 1rem 0;
  }

  .nav-menu.active {
    left: 0;
    opacity: 1;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 1rem;
    width: 100%;
  }

  .nav-links:hover {
    background-color: white;
    color: #242424;
  }

  .fa-bars {
    color: #C6ECFF;
  }

  .fa-times {
    color: #C6ECFF;
    font-size: 2rem;
  }
}
